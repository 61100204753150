<template>
  <HostLayoutContainer :style="{ height: '905px' }">
    <HostLayoutItem>
      <div class="resoure_administration-container">
        <TitleWithUnderline :title="title" id="resoure-administration"/>
        <div class="resoure_administration-img">
          <img src="/assets/service/cloud-service.png" alt="" />
        </div>
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>
<script>
import TitleWithUnderline from '@/components/title/title-with-underline.vue'
export default {
  components: {
    TitleWithUnderline,
  },
  setup () {
    return {
      title: { marginBottom: '103px', marginTop: '75px', text: '专业的云管理服务能力' },
    }
  }
}
</script>
<style lang="scss" scoped>
.resoure_administration-img {
  width: 1200px;
  height: 575px;
  margin: 0 auto;
}
</style>
